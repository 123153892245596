<template class="text_google">
  <v-app>
    <v-system-bar
      class="noprint"
      color="grey darken-2"
      dark
      height="40"
      app
      lights-out
    >
      <v-toolbar-items class="ml-5">
        <v-btn
          @click.stop="exportToExcel('exportContent', 'ManPower_Import_Form')"
          class="title elevation-0"
          color="grey darken-1"
          x-large
        >
          <v-icon>mdi-file-excel</v-icon>&nbsp; Export to CSV
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>
    <div id="exportContent" style="mso-page-orientation: landscape;">
      <div class="page">
        <div class="head text-center">
          ผลการเรียกรายงานตัวครูผู้ช่วย
        </div>
        <table class="table" border="1" width="100%">
          <tr>
            <td class="regular16 pa-1 text-center">ลำดับ</td>
            <td class="regular16 pa-1 text-center">รหัสสาขาวิชาที่สอบ</td>
            <td class="regular16 pa-1 text-center">ลำดับที่สอบได้</td>
            <td class="regular16 pa-1 text-center">รหัสบัตรประชาชน</td>
            <td class="regular16 pa-1 text-center">คำนำหน้าชื่อ</td>
            <td class="regular16 pa-1 text-center">ชื่อ</td>
            <td class="regular16 pa-1 text-center">นามสกุล</td>
            <td class="regular16 pa-1 text-center">วันเกิด</td>
            <td class="regular16 pa-1 text-center">เดือนเกิด</td>
            <td class="regular16 pa-1 text-center">ปีเกิด</td>
            <td class="regular16 pa-1 text-center">เบอร์โทร</td>
            <td class="regular16 pa-1 text-center">ชื่อย่อวุฒิการศึกษา</td>
            <td class="regular16 pa-1 text-center">สาขาวิชาเอก</td>
            <td class="regular16 pa-1 text-center">วันที่บรรจุ</td>
            <td class="regular16 pa-1 text-center">เดือนที่บรรจุ</td>
            <td class="regular16 pa-1 text-center">ปีที่บรรจุ</td>
            <td class="regular16 pa-1 text-center">คำสั่งที่</td>
            <td class="regular16 pa-1 text-center">วิธีการ</td>
            <td class="regular16 pa-1 text-center">เขตพื้นที่</td>
            <td class="regular16 pa-1 text-center">รหัสวิทยาลัย</td>
            <td class="regular16 pa-1 text-center">วิทยาลัย</td>
            <td class="regular16 pa-1 text-center">เลขที่ตำแหน่ง</td>
            <td class="regular16 pa-1 text-center">รายงานตัว</td>
          </tr>
          <tr v-for="(item, index) in manage_assistant_teachers" :key="item.id">
            <td class="regular16 pa-1 text-center">{{ index + 1 }}</td>
            <td class="regular16 pa-1 text-center">{{ item.mt_id_branch }}</td>
            <td class="regular16 pa-1 text-center">{{ item.mt_sequence }}</td>
            <td class="regular16 pa-1 text-center">{{ item.mt_id_card }}</td>
            <td class="regular16 pa-1 text-center">{{ item.mt_title_s }}</td>
            <td class="regular16 pa-1 text-center">{{ item.mt_frist_name }}</td>
            <td class="regular16 pa-1 text-center">{{ item.mt_last_name }}</td>
            <td class="regular16 pa-1 text-center">{{ item.mt_brith_day }}</td>
            <td class="regular16 pa-1 text-center">
              {{ item.mt_brith_month }}
            </td>
            <td class="regular16 pa-1 text-center">{{ item.mt_brith_year }}</td>
            <td class="regular16 pa-1 text-center">{{ item.mt_tel_p }}</td>
            <td class="regular16 pa-1 text-center">{{ item.mt_ed_abb }}</td>
            <td class="regular16 pa-1 text-center">{{ item.mt_ed_name }}</td>
            <td class="regular16 pa-1 text-center">{{ item.mt_appoin_day }}</td>
            <td class="regular16 pa-1 text-center">
              {{ item.mt_appoin_month }}
            </td>
            <td class="regular16 pa-1 text-center">
              {{ item.mt_appoin_year }}
            </td>
            <td class="regular16 pa-1 text-center">{{ item.mt_order_app }}</td>
            <td class="regular16 pa-1 text-center">
              <span v-if="item.mt_examtype === 'select'">คัดเลือก</span>
              <span v-else>สรรหา</span>
            </td>
            <td class="regular16 pa-1 text-center">
              <span v-if="item.mt_examgroup === 'general'">ทั่วไป</span>
              <span v-else>จชต.</span>
            </td>
            <td class="regular16 pa-1 text-center">
              {{ item.mt_college_code }}
            </td>
            <td class="regular16 pa-1 text-center">{{ item.college_name }}</td>
            <td class="regular16 pa-1 text-center">
              {{ item.mt_id_position }}
            </td>
            <td class="regular16 pa-1 text-center">
              <span v-if="item.mt_admissions_status ==='send'">รอรายงานตัว</span>
              <span v-else-if="item.mt_admissions_status ==='receipt'">รายงานตัวปกติ</span>
              <span v-else-if="item.mt_admissions_status ==='missing'">สละสิทธิ์</span>    
            </td>
          </tr>
        </table>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    manage_assistant_teachers: []
  }),

  async mounted() {
    /*  await this.exportToExcel('exportContent', 'ManPower_Import_Form')
     await this.close_window() */
    await this.manage_assistant_teacherQueryAll();
  },

  methods: {
    async manage_assistant_teacherQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_times: this.mt_times,
          mt_years: this.mt_years,
           mt_report: "Ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async close_window() {
      close();
    },
    exportToExcel(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Excel</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msexcel"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-excel;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".xls" : "document.xls";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    mt_times() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    },

    mt_years() {
      let result = window.location.href;
      let split_s = result.split("/");

      return split_s[7];
    }
  },

  props: {
    source: String
  }
};
</script>
<style>
@import url(
  https://fonts.googleapis.com/css?family=Prompt:400,
  300|Roboto:400,
  300&subset=latin,
  thai
);

body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.textAlignVer {
  display: block;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  width: 20px;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 5;
}

u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 40cm;
  min-height: 21cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 0.5cm;
  padding-right: 0.5cm;
  padding-bottom: 0.5cm;
  padding-left: 0.5cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
      height: 247mm;
    }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    border: initial;
    border-radius: initial;
    /* width: initial; */
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding: initial;
    page-break-after: always;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
        font-size: 18px;
        font-weight: bold;
      }
      .regular12 {
        font-size: 12px;
      }
      .blod12 {
        font-size: 12px;
        font-weight: bold;
      }
      .blackRegula8 {
        font-size: 8px;
      } */
  .noprint {
    display: none;
  }
}
</style>
